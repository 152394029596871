<template>
  <div>
    <md-card class="md-card-global-sales">
      <md-card-header class="md-card-header-icon md-card-header-blue">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <md-button
              class="md-just-icon md-round md-primary pull-right"
              @click="newAgency"
            >
              <md-icon>add</md-icon>
              <md-tooltip>New Agency</md-tooltip>
            </md-button>

            <div
              class="d-inline-block pull-right"
            >
              <md-switch
                v-model="filters.show_deleted"
                @change="$refs.vtable.init()"
              >
                Show Inactives
              </md-switch>
            </div>

            <vue-table
              v-if="vTable.headers"
              ref="vtable"
              :values="vTable.values"
              :headers="vTable.headers"
              :actions="vTable.actions"
              :options="{
                tableClass:'table'
              }"
              @changed="getAgencies"
              @onDelete="onDelete"
              @onRestore="onRestore"
              @onEdit="onEdit"
            />
          </div>
        </div>
      </md-card-content>
    </md-card>

    <AgenciesForm
      v-if="showModal"
      :item="selectedItem"
      @close="closeModal"
      @onSaved="$refs.vtable.init()"
    />
  </div>
</template>

<script>
import VueTable from '@/components/Tables/VueTable.vue';
import AgenciesForm from './AgenciesForm.vue';

export default {
  components: {
    VueTable,
    AgenciesForm,
  },
  props: [],
  data() {
    return {
      selectedItem: null,
      filters: {
        show_deleted: false,
      },
      vTable: {
        headers: [
          {
            title: 'agency_id',
            mask: 'Agency ID',
            no_format: true,
            sortable: true,
          },
          {
            title: 'name',
            sortable: true,
          },
          {
            title: 'email',
            sortable: true,
          },
          {
            title: 'commission',
            sortable: true,
          },
        ],
        actions: [
          {
            buttonClass: 'md-danger',
            tooltip: 'Deactivate Agency',
            callback: 'onDelete',
            icon: 'close',
          },
          {
            buttonClass: 'md-success',
            tooltip: 'Restore Agency',
            callback: 'onRestore',
            icon: 'check',
          },
          {
            buttonClass: 'md-info',
            tooltip: 'Edit Agency',
            callback: 'onEdit',
            icon: 'search',
          },
        ],
        values: {},
      },
      showModal: false,
    };
  },
  mounted() {
    this.$refs.vtable.init();
  },
  methods: {
    getAgencies(params) {
      this.request('agencies', 'get', { ...params, ...this.filters }, ({ data }) => {
        this.vTable.values = data;
        this.vTable.values.data = this.vTable.values.data.map((x) => ({
          ...x,
          onDelete: !x.deleted_at,
          onRestore: !!x.deleted_at,
        }));
      });
    },
    newAgency() {
      this.showModal = true;
    },
    onEdit(item) {
      this.showModal = true;
      this.selectedItem = item;
    },
    closeModal() {
      this.showModal = false;
      this.selectedItem = null;
    },
    onDelete(item) {
      this.fireConfirm(
        'Deactivating Agency',
        `Are you sure you want to deactivate this Agency (${item.name})?`,
      )
        .then(() => {
          this.request(`agencies/${item.agency_id}`, 'delete', {}, () => {
            this.fireSuccess('Agency deactivated successfully.');
            // Reset table after response
            this.$refs.vtable.init();
          });
        });
    },
    onRestore(item) {
      this.fireConfirm(
        'Restoring Agency',
        `Are you sure you want to restore this Agency (${item.name})?`,
      )
        .then(() => {
          this.request(`agencies/${item.agency_id}`, 'put', {
            ...item,
            restore: true,
          }, () => {
            this.fireSuccess('Agency restored successfully.');
            // Reset table after response
            this.$refs.vtable.init();
          });
        });
    },
  },
};
</script>

<style scoped>
</style>
