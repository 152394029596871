<template>
  <vue-modal
    ref="modal"
    size="sm"
    @close="$emit('close')"
  >
    <template slot="title">
      Agency Form
    </template>
    <template slot="body">
      <ValidationObserver ref="form">
        <FormText
          v-model="form.name"
          label="Name"
          asterisk
          rules="required"
        />
        <FormText
          v-model="form.email"
          label="Email"
          asterisk
          rules="required"
        />
        <FormNumber
          v-model="form.commission"
          label="Commission"
          asterisk
          rules="required"
        />
      </ValidationObserver>
    </template>
    <template slot="footer">
      <md-button
        class="md-success pull-right"
        @click="saveAgency"
      >
        Save
      </md-button>
    </template>
  </vue-modal>
</template>
<script>
import { FormText, FormNumber } from '@/components/Inputs';

import { extend } from 'vee-validate';
import { required, min } from 'vee-validate/dist/rules';

extend('min', min);
extend('required', required);

export default {
  components: {
    FormText,
    FormNumber,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    form: {
      name: null,
      email: null,
      commission: null,
    },
  }),
  mounted() {
    if (this.item) this.form = { ...this.item };
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
    saveAgency() {
      this.$refs.form.validate().then((res) => {
        if (res) {
          if (this.item) {
            this.request(
              `agencies/${this.item.agency_id}`,
              'put',
              { ...this.form },
              () => {
                this.fireSuccess('Agency updated successfuly');
                this.$emit('onSaved');
                this.close();
              },
            );
          } else {
            this.request('agencies', 'post', { ...this.form }, () => {
              this.fireSuccess('Agency created successfuly');
              this.$emit('onSaved');
              this.close();
            });
          }
        } else {
          this.fireError('Please, complete all the required fields before.');
        }
      });
    },
  },
};
</script>
<style></style>
